import React, { useState } from 'react'
import { Button } from '../../common/button'
import PropTypes from 'prop-types'
import { isLoggedIn } from '../../../services/authentication'
import { Lock } from '../../../images/lock'
import { UserIcon } from '../../../images/user-icon'
import { Arrow } from '../../../images/arrow'
import Paywall from '../../paywall/Paywall'
import thumbnail from '../../../images/video-thumbnail.jpg'
import { Puff } from 'react-loader-spinner'
import { post } from 'aws-amplify/api'
import { displayWebinarError } from '../../toasts/displayWebinarError'

const Overlay = ({
  courseCosts,
  price,
  productId,
  priceId,
  title,
  userHasPaid,
  loading,
  path,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [clientSecret, setClientSecret] = useState(null)
  const jwt = typeof window !== 'undefined' && localStorage.getItem('jwt')

  async function initPayment() {
    if (!jwt) {
      return
    }

    setShowModal(true)

    // Make the request
    try {
      const restOperation = post({
        apiName: 'stripeapi',
        path: '/initialize-checkout',
        options: {
          body: {
            priceId,
            token: JSON.parse(jwt).value,
            productId,
            path,
          },
        }
      })

      const { body } = await restOperation.response;
      const response = await body.json();

      if (response.paymentIntent.client_secret) {
        setClientSecret(response.paymentIntent.client_secret)
      }
    } catch (error) {
      displayWebinarError()
    }
  }

  const showPaywall = isLoggedIn() && showModal && !userHasPaid
  const userHasNotPaid = isLoggedIn() && courseCosts && !userHasPaid
  return (
    <>
      <div className={'bg-gray-100'}>
        {showPaywall && (
          <Paywall
            paid={courseCosts}
            price={price}
            productId={productId}
            priceId={priceId}
            setShowModal={setShowModal}
            title={title}
            clientSecret={clientSecret}
          />
        )}
        <div
          className={`container max-w-[72.875rem] mx-auto flex items-center justify-center py-16 relative `}
        >
          {loading && (
            <div
              className={
                'flex justify-center items-center w-full h-[10.5rem] md:h-[24.75rem] lg:h-[41.313rem]'
              }
            >
              <Puff
                height="80"
                width="80"
                radius={1}
                color="#E36F1E"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          {!loading && (
            <>
              <img
                className={
                  'opacity-30 rounded-md w-full object-cover blur-[6px]'
                }
                src={thumbnail}
                alt={'video bilde'}
              />
              <div
                className={
                  'absolute flex w-full flex-col justify-center items-center mx-auto text-center container max-w-[40rem]'
                }
              >
                <div className={'p-3 bg-blue-lighter rounded-full'}>
                  <Lock />
                </div>
                <p
                  className={
                    'text-2xl md:text-3xl lg:text-4xl font-semibold text-blue-dark mt-4'
                  }
                >
                  {userHasNotPaid
                    ? `Kjøp tilgang til dette webinar-opptaket for ${price} kr`
                    : 'Logg inn som Proteket-medlem for tilgang til webinarer'}
                </p>
                {userHasNotPaid ? (
                  <div className={'flex flex gap-2 mt-5'}>
                    <button
                      className={
                        'text-center px-[18px] pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light font-medium bg-orange-main text-orange-lightest hover:bg-orange-light active:bg-orange-darkest '
                      }
                      onClick={initPayment}
                    >
                      Betal med kort
                    </button>
                  </div>
                ) : (
                  <div className={'flex flex gap-2 mt-5'}>
                    <Button
                      variant={'icon'}
                      path={'/registrer-bruker'}
                      isExternal={false}
                      color={'orange'}
                    >
                      <UserIcon color={'#fff'} size={24} />
                      Bli medlem
                    </Button>
                    <Button
                      variant={'icon'}
                      color={'blue'}
                      path={'/logg-inn'}
                      isExternal={false}
                    >
                      Logg inn
                      <Arrow color={'white'} />
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Overlay

Overlay.propTypes = {
  paid: PropTypes.bool,
  price: PropTypes.number,
  productId: PropTypes.string,
  priceId: PropTypes.string,
  title: PropTypes.string,
  userHasPaid: PropTypes.bool,
}