import React from 'react'
import PropTypes from 'prop-types'

const IntroSection = ({ introduction }) => {
  return (
    <div
      className={
        'w-full mx-auto relative h-max text-left  pt-6 md:pt-8 lg:pt-12'
      }
    >
      <p className={'md-container mx-auto'}>{introduction}</p>
      <div className={'absolute bottom-0 h-max'}>
        <div
          className={'bg-gradient-to-t from-gray-100 to-white/40 h-48 w-screen'}
        />
      </div>
      <div className={'pb-6 md:pb-8 lg:pb-12'} />
    </div>
  )
}

export default IntroSection

IntroSection.propTypes = {
  introduction: PropTypes.string,
}