import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import Breadcrumbs from '../../breadcrumbs'

const WebinarHeader = ({
  breadcrumbs,
  title,
  leading,
  date,
  tags,
  speakers,
}) => {
  return (
    <>
      <div className={'xl-container mx-auto'}>
        <div className={'pt-[1.125rem] md:pt-[1.625rem] lg:pt-[2.125rem]'}>
          <Breadcrumbs crumbs={breadcrumbs} />
        </div>
        <div
          className={
            'mt-8 md:mt-12 lg:mt-16 text-center mx-auto max-w-[42.5rem]'
          }
        >
          <h1
            className={
              'text-4xl md:text-5xl lg:text-6xl font-medium mb-3 md:mb-[1.125rem] lg:mb-6 text-blue-main'
            }
          >
            {title}
          </h1>
          {leading && (
            <h2 className={'text-lg lg:text-xl'}>{parse(leading)}</h2>
          )}
          <div
            className={
              'grid grid-cols-2 gap-4 items-center divide-x mt-6 mb-6 md:mt-8 lg:mt-9'
            }
          >
            <div
              className={
                'flex flex-wrap gap-y-2 gap-x-1 justify-center justify-self-end'
              }
            >
              {tags.length > 0 &&
                tags.slice(0, 2).map((item, index) => (
                  <span
                    key={index}
                    className={
                      'pt-2 px-3 pb-2.5 w-max bg-blue-lighter rounded-full flex items-center justify-center'
                    }
                  >
                    {item.name}
                  </span>
                ))}
            </div>
            <div className={'justify-self-start px-6'}>
              <span className={'text-gray-500'}>{date}</span>
            </div>
          </div>
        </div>
        <div
          className={
            'flex flex-wrap gap-6 justify-start md:justify-center mb-9'
          }
        >
          {speakers &&
            speakers.map((item, index) => (
              <div className={'flex gap-2.5'} key={index}>
                <img
                  className={
                    'h-16 w-16 rounded-full overflow-hidden object-cover'
                  }
                  src={item.image.publicUrl}
                  alt={item.image.altText ?? ''}
                />
                <div className={'flex flex-col justify-center'}>
                  <p className={'font-semibold text-blue-dark'}>{item.name}</p>
                  <p className={'text-blue-dark'}>{item.position}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default WebinarHeader

WebinarHeader.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
}