import React from 'react'
import PropTypes from 'prop-types'

const SuccessModal = ({ setSuccess }) => {
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className={'bg-white p-8 rounded-md flex flex-col gap-4'}>
        <h3 className={'text-2xl font-semibold'}>
          Gratulerer, kjøpet er gjennomført!
        </h3>
        <p>Du har nå tilgang til webinaret</p>

        <button
          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={() => setSuccess(false)}
        >
          Gå til webinar
        </button>
      </div>
    </div>
  )
}

export default SuccessModal

SuccessModal.propTypes = {
  setSuccess: PropTypes.func.isRequired,
}