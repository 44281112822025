import React from 'react'

export const Lock = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6522 8.43637C14.884 6.72561 16.9259 5.41675 20 5.41675C23.0741 5.41675 25.116 6.72561 26.3478 8.43637C27.5336 10.0834 27.9167 12.0223 27.9167 13.3334V18.7501H29C30.2427 18.7501 31.25 19.7574 31.25 21.0001V32.3334C31.25 33.5761 30.2427 34.5834 29 34.5834H11C9.7574 34.5834 8.75 33.5761 8.75 32.3334V21.0001C8.75 19.7574 9.7574 18.7501 11 18.7501H12.0833V13.3334C12.0833 12.0223 12.4664 10.0834 13.6522 8.43637ZM14.5833 18.7501H25.4167V13.3334C25.4167 12.4223 25.1331 11.0279 24.3189 9.89713C23.5507 8.83011 22.2593 7.91675 20 7.91675C17.7407 7.91675 16.4493 8.83011 15.6811 9.89713C14.8669 11.0279 14.5833 12.4223 14.5833 13.3334V18.7501ZM11.25 21.2501V32.0834H28.75V21.2501H11.25Z"
      fill="#001529"
    />
  </svg>
)