import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import WebinarHeader from './WebinarHeader'
import { isLoggedIn } from '../../../services/authentication'
import { lockedPageEvent } from '../../google-tag-manager/events'
import Content from './Content'
import Overlay from './Overlay'
import IntroSection from './IntroSection'
import { post } from 'aws-amplify/api'
import { displayWebinarError } from '../../toasts/displayWebinarError'

const Webinar = ({
  title,
  leading,
  content,
  introduction,
  date,
  tags,
  breadcrumbs,
  speakers,
  price,
  courseCosts,
  priceId,
  productId,
  id,
  path,
}) => {
  useEffect(() => {
    isLoggedIn() ? lockedPageEvent(false) : lockedPageEvent(true)
  }, [])

  const [videoLink, setVideoLink] = useState('')
  const [userHasPaid, setUserHasPaid] = useState(false)
  const [loading, setLoading] = useState(false)
  const jwt = typeof window !== 'undefined' && localStorage.getItem('jwt')

  useEffect(() => {
    async function fetchData() {
      setLoading(true)

      try {
        const restOperation = post({
          apiName: 'webinarapi',
          path: `/webinar/${id}`,
          options: {
            body: {
              id: id,
              token: JSON.parse(jwt).value,
              productId: productId,
            },
          }
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        if (response) {
          setUserHasPaid(response?.hasPaid)
          setVideoLink(response?.webinarData?.videoLink)
          setLoading(false)
        }
      } catch (error) {
        displayWebinarError()
      }
    }

    jwt && fetchData()
  }, [jwt])

  const userHasNotPaidOrIsNotLoggedIn =
    (isLoggedIn() && courseCosts && !userHasPaid) || !isLoggedIn()
  const userHasAccessToCourse =
    (isLoggedIn() && userHasPaid) || (isLoggedIn() && !courseCosts)

  return (
    <div className={'bg-white'}>
      <WebinarHeader
        title={title}
        tags={tags}
        breadcrumbs={breadcrumbs}
        date={date}
        leading={leading}
        speakers={speakers}
      />

      {userHasNotPaidOrIsNotLoggedIn && (
        <Overlay
          courseCosts={courseCosts}
          price={price}
          productId={productId}
          priceId={priceId}
          title={title}
          userHasPaid={userHasPaid}
          loading={loading}
          path={path}
        />
      )}
      {introduction && userHasNotPaidOrIsNotLoggedIn && (
        <IntroSection introduction={introduction} />
      )}

      {userHasAccessToCourse && (
        <Content content={content} videoLink={videoLink} loading={loading} />
      )}
    </div>
  )
}

export default Webinar

Webinar.propTypes = {
  title: PropTypes.string,
  leading: PropTypes.string,
  introduction: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  breadcrumbs: PropTypes.array,
  videoLink: PropTypes.string,
  speakers: PropTypes.arrayOf(PropTypes.object),
}