import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Puff } from 'react-loader-spinner';
import CheckoutForm from './CheckoutForm';
import { CloseIcon } from '../../images/close-icon';

// Should use public key as env variable (when we have the final stripe account)
const stripePromise = loadStripe(
  `${process.env.GATSBY_STRIPE_PK}`
)

const Paywall = ({ price, setShowModal, title, clientSecret }) => {

  return (
    <>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
          }}
        >
          <CheckoutForm
            setShowModal={setShowModal}
            title={title}
            price={price}
          />
        </Elements>
      ) : (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={'bg-white p-8 rounded-md flex flex-col gap-4'}>
              <button className={
                'flex justify-end -mr-4 -mt-4'
              } onClick={() => setShowModal(false)}>
                <CloseIcon />
              </button>
              <div className="flex flex-col items-center">
                <Puff
                  height="80"
                  width="80"
                  radius={1}
                  color="#E36F1E"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
                <h3 className={'text-lg mt-8 text-'}>Laster inn betalingsinformasjon...</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Paywall

Paywall.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  setShowModal: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
}