import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Puff } from 'react-loader-spinner'

const Content = ({ videoLink, content, loading }) => {
  return (
    <>
      <div className={'bg-gray-100'}>
        <div
          className={`container w-full mx-auto flex items-center justify-center py-6 md:py-8 lg:py-16 relative `}
        >
          {loading ? (
            <div
              className={
                'flex justify-center items-center w-full h-[10.5rem] md:h-[24.75rem] lg:h-[41.313rem]'
              }
            >
              <Puff
                height="80"
                width="80"
                radius={1}
                color="#E36F1E"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <iframe
                src={videoLink}
                className={
                  'w-[25.5rem] h-[10.5rem] md:h-[24.75rem] md:w-[44rem] lg:h-[41.313rem] lg:w-[72.875rem] rounded-md'
                }
                allow={'autoplay; fullscreen; picture-in-picture; controls'}
                allowFullScreen={true}
              />

              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          )}
        </div>
      </div>
      <div
        className={
          'w-full mx-auto relative h-max text-left py-6 md:py-8 lg:py-12'
        }
      >
        {content && (
          <div className={'md-container mx-auto rich-content'}>{parse(content)}</div>
        )}
      </div>
    </>
  )
}

export default Content

Content.propTypes = {
  content: PropTypes.string,
  videoLink: PropTypes.string,
}