import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Oval } from 'react-loader-spinner'
import PropTypes from 'prop-types'

const translations = {
  invalid_number: 'Kortnummeret er ikke et gyldig kredittkortnummer',
  invalid_expiry_month: 'Kortets utløpsmåned er ugyldig',
  invalid_expiry_year: 'Kortets utløpsår er ugyldig',
  invalid_cvc: 'Kortets sikkerhetskode er ugyldig',
  incorrect_number: 'Kortnummeret er feil',
  incomplete_number: 'Kortnummeret er ufullstendig',
  incomplete_cvc: 'Kortets sikkerhetskode er ufullstendig',
  incomplete_expiry: 'Kortets utløpsdato er ufullstendig',
  expired_card: 'Kortet er utløpt',
  incorrect_cvc: 'Kortets sikkerhetskode er feil',
  incorrect_zip: 'Kortets postnummer mislyktes ved validering',
  invalid_expiry_year_past: 'Kortets utløpsår er i fortiden',
  card_declined: 'Kortet ble avvist',
  missing: 'Det er ikke noe kort på en kunde som blir belastet',
  processing_error: 'Det oppstod en feil under behandling av kortet',
}

const CheckoutForm = ({ setShowModal, title, price }) => {
  const stripe = useStripe()

  const elements = useElements()
  const [message, setMessage] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.href}#success`,
      },
    })
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(translations[error.code])
    } else {
      setMessage('En uventet feil oppstod.')
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col items-start justify-between px-5 pt-5 pb-2 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold max-w-sm">
                Kjøp tilgang til {title}
              </h3>
              <div className={'flex justify-between w-full gap-2 mt-5'}>
                <div className={'flex items-center gap-1 mt-2'}>
                  <span className={'text-lg font-semibold'}>{price}kr</span>
                  <span className={'text-sm text-gray-500'}>
                    Inkludert moms
                  </span>
                </div>
                <div className={'flex items-center gap-1 mt-2'}>
                  <span>Engangsbetaling</span>
                </div>
              </div>
            </div>
            <div className="relative px-6 py-5 flex-auto">
              <PaymentElement />
            </div>
            <div className={'px-6 pb-2 text-base text-red-500'}>{message}</div>
            <div
              className={
                'flex flex-col pb-6 px-6 pt-1 border-t border-solid border-slate-200 rounded-b'
              }
            >
              <div className={'text-sm text-gray-500 text-center px-5 py-2'}>
                Kjøpet gir permanent tilgang til dette webinaret.
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Avbryt
                </button>
                <button
                  disabled={isLoading || !stripe}
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  id={'submit'}
                >
                  {isLoading ? (
                    <Oval
                      height={16}
                      width={16}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    'Betal'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </form>
  )
}

export default CheckoutForm

CheckoutForm.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  setShowModal: PropTypes.func.isRequired
}