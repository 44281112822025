import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Webinar from '../components/webinars/webinar'
import Slider from '../components/courses/course/slider'
import SuccessModal from '../components/paywall/SuccessModal'

const WebinarTemplate = ({ data, location }) => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const webinars = data.allWpWebinar?.nodes
  const slider = data.allWpPage.nodes[0].template.templateWebinars
  const [success, setSuccess] = useState(location?.hash === '#success')
  return (
    <Layout template={'Webinar Template'} location={location}>
      <SEO
        title={data.wpWebinar.title}
        metaTitle={data.wpWebinar.seo.title}
        description={data.wpWebinar.seo.metaDesc}
      />
      <Webinar
        speakers={data.wpWebinar?.templateWebinar.speakersList}
        leading={data.wpWebinar?.templateWebinar.leadingText}
        title={data.wpWebinar?.title}
        introduction={data.wpWebinar?.templateWebinar.introductionText}
        content={data.wpWebinar?.templateWebinar.wysiwygContent}
        breadcrumbs={data.wpWebinar?.seo.breadcrumbs}
        tags={data.wpWebinar?.categories.nodes}
        date={data.wpWebinar?.date}
        price={data.wpWebinar?.templateWebinar.price}
        courseCosts={data.wpWebinar?.templateWebinar.paid}
        productId={data.wpWebinar?.templateWebinar.productId}
        priceId={data.wpWebinar?.templateWebinar.priceId}
        id={data.wpWebinar.id}
        path={location.pathname}
      />

      {webinars.length > 0 && (
        <Slider
          list={webinars}
          title={slider.sliderTitle}
          subtitle={slider.sliderDescription}
        />
      )}

      {success && <SuccessModal setSuccess={setSuccess} />}
    </Layout>
  )
}

export default WebinarTemplate

export const query = graphql`
  query ($id: String) {
    wpWebinar(id: { eq: $id }) {
      title
      id
      date(formatString: "DD.MM.YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
      }
      templateWebinar {
        leadingText
        wysiwygContent
        introductionText
        speakersList {
          image {
            altText
            publicUrl
          }
          position
          name
        }
        price
        paid
        productId
        priceId
      }
      categories {
        nodes {
          name
          slug
          taxonomyName
        }
      }
    }
    allWpWebinar(
      sort: { fields: date, order: ASC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        date
        templateWebinar {
          introductionText
          leadingText
          speakersList {
            image {
              publicUrl
              altText
            }
            position
            name
          }
          wysiwygContent
          price,
          paid
        }
        title
        id
        uri
        categories {
          nodes {
            name
            slug
            taxonomyName
          }
        }
      }
    }
    allWpCategory {
      nodes {
        name
        slug
      }
    }
    allWpPage(
      filter: { template: { templateName: { eq: "Webinar Template" } } }
    ) {
      nodes {
        id
        template {
          ... on WpWebinarTemplate {
            templateName
            templateWebinars {
              sliderDescription
              sliderTitle
            }
          }
        }
      }
    }
  }
`